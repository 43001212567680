import React from "react"

import Page from "./page"
import Lines from "../../images/lines2.svg"
import "./SimplePage.css"

const SimplePage = ({ title, children }) => {
  const marginTop = title ? -30 : -18

  return (
    <Page title={title}>
      <div className="markdown1">
        <div className="bg-light">
          <div className="container mt-0 ">
            {title && (
              <div className="">
                <h1 className=" pt-3 pb-0  mb-0">{title}</h1>
              </div>
            )}
          </div>
          <div className="simplePage-lines" style={{ transform: "scaleX(-1)" }}>
            <Lines />
          </div>
        </div>
        <div className="container mt-4 ">{children}</div>
      </div>
    </Page>
  )
}

export default SimplePage
