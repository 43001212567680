import React from "react"
import { graphql } from "gatsby"
import SimplePage from "../components/page/SimplePage"
import { Offer } from "../components/offers/Offer"
import { GetInvite } from "../components/GetInvite"

export default function Template({ data }) {
  console.log("data", data)

  const offer = data.offersJson
  return (
    <SimplePage title={offer.name}>
      <div className="row align-items-center mb-5">
        <div className="col-12">
          <Offer offer={offer} preview={false} />
        </div>
        <div className="col-12">
          <GetInvite page="offer" />
        </div>
      </div>
    </SimplePage>
  )
}

export const offerFragment = graphql`
  fragment OfferFragment on OffersJson {
    name
    teaser
    bottle {
      price
      size
      deliveryPrice
      sizeMl
    }
    sample {
      price
      size
      fee
      sizeMl
    }
    samples {
      all
      free
    }
    previewImage: imageFile {
      childImageSharp {
        fixed(height: 240) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fluidImage: imageFile {
      childImageSharp {
        fluid(maxWidth: 200, maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fullImage: imageFile {
      childImageSharp {
        fixed(height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    description
    slug
    link
  }
`

export const pageQuery = graphql`
  query($id: String!) {
    offersJson(id: { eq: $id }) {
      ...OfferFragment
    }
  }
`
